@font-face {
    font-family: 'icomoon';
    src: url(../../../public/fonts/v2/icomoon.eot?jkb5zc);
    src: url(../../../public/fonts/v2/icomoon.eot?jkb5zc#iefix) format('embedded-opentype'),
        url(../../../public/fonts/v2/icomoon.ttf?jkb5zc) format('truetype'),
        url(../../../public/fonts/v2/icomoon.woff?jkb5zc) format('woff'),
        url(../../../public/fonts/v2/icomoon.svg?jkb5zc#icomoon) format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-deployable-align-center:before {
    content: '\e956';
}
.icon-deployable-align-left:before {
    content: '\e954';
}
.icon-deployable-align-right:before {
    content: '\e955';
}
.icon-deployable-border-radius:before {
    content: '\e950';
}
.icon-deployable-margin-link:before {
    content: '\e951';
}
.icon-deployable-margin-unlink:before {
    content: '\e952';
}
.icon-deployable-widget-button:before {
    content: '\e93a';
    color: #3c4b61;
}
.icon-deployable-widget-checkbox:before {
    content: '\e93b';
}
.icon-deployable-widget-container:before {
    content: '\e93c';
}
.icon-deployable-widget-datepicker:before {
    content: '\e93d';
}
.icon-deployable-widget-formblock:before {
    content: '\e93e';
}
.icon-deployable-widget-iframe:before {
    content: '\e93f';
}
.icon-deployable-widget-image:before {
    content: '\e940';
}
.icon-deployable-widget-inputbox:before {
    content: '\e941';
}
.icon-deployable-widget-link:before {
    content: '\e942';
}
.icon-deployable-widget-logo:before {
    content: '\e943';
}
.icon-deployable-widget-modal:before {
    content: '\e944';
}
.icon-deployable-widget-radio:before {
    content: '\e945';
}
.icon-deployable-widget-richtext:before {
    content: '\e946';
}
.icon-deployable-widget-scratchcard:before {
    content: '\e947';
}
.icon-deployable-widget-select:before {
    content: '\e948';
    color: #3c4b61;
}
.icon-deployable-widget-social:before {
    content: '\e949';
}
.icon-deployable-widget-spinwheel:before {
    content: '\e94a';
    color: #c4c4c4;
}
.icon-deployable-widget-starrating:before {
    content: '\e94b';
}
.icon-deployable-widget-submit:before {
    content: '\e94c';
    color: #3c4b61;
}
.icon-deployable-widget-textarea:before {
    content: '\e94d';
}
.icon-deployable-widget-upload:before {
    content: '\e94e';
}
.icon-deployable-widget-video:before {
    content: '\e94f';
}
.icon-deployable-social-fb-black:before {
    content: '\e931';
}
.icon-deployable-social-insta-black:before {
    content: '\e932';
}
.icon-deployable-social-linkedIn-black:before {
    content: '\e933';
}
.icon-deployable-social-pinterest-black:before {
    content: '\e934';
}
.icon-deployable-social-spotify-black:before {
    content: '\e935';
}
.icon-deployable-social-tiktok-black:before {
    content: '\e936';
}
.icon-deployable-social-tweet-black:before {
    content: '\e937';
}
.icon-deployable-social-whatapp-black:before {
    content: '\e938';
}
.icon-deployable-social-yt-black:before {
    content: '\e939';
}
.icon-deployable--image:before {
    content: '\e900';
}
.icon-deployable-account:before {
    content: '\e901';
}
.icon-deployable-add:before {
    content: '\e902';
}
.icon-deployable-arrow-drop-down:before {
    content: '\e903';
    color: #3c4b61;
}
.icon-deployable-arrow-drop-up:before {
    content: '\e904';
    color: #3c4b61;
}
.icon-deployable-arrow-left:before {
    content: '\e905';
    color: #3c4b61;
}
.icon-deployable-assets:before {
    content: '\e906';
}
.icon-deployable-bin:before {
    content: '\e907';
}
.icon-deployable-brand:before {
    content: '\e908';
}
.icon-deployable-close:before {
    content: '\e909';
}
.icon-deployable-cloud-upload:before {
    content: '\e90a';
    color: #3c4b61;
}
.icon-deployable-copy:before {
    content: '\e90b';
    color: #3c4b61;
}
.icon-deployable-cross:before {
    content: '\e90c';
}
.icon-deployable-dots:before {
    content: '\e90d';
    color: #717f95;
}
.icon-deployable-down:before {
    content: '\e90e';
    color: #6f767e;
}
.icon-deployable-download:before {
    content: '\e90f';
}
.icon-deployable-edit:before {
    content: '\e910';
}
.icon-deployable-eye:before {
    content: '\e911';
}
.icon-deployable-layers:before {
    content: '\e912';
}
.icon-deployable-layout:before {
    content: '\e913';
}
.icon-deployable-left:before {
    content: '\e914';
    color: #6f767e;
}
.icon-deployable-link:before {
    content: '\e915';
}
.icon-deployable-logo:before {
    content: '\e916';
    color: #fff;
}
.icon-deployable-logout:before {
    content: '\e917';
}
.icon-deployable-open-link:before {
    content: '\e918';
    color: #3c4b61;
}
.icon-deployable-plus:before {
    content: '\e919';
}
.icon-deployable-present:before {
    content: '\e91a';
}
.icon-deployable-qr:before {
    content: '\e91b';
}
.icon-deployable-right:before {
    content: '\e91c';
}
.icon-deployable-rocket:before {
    content: '\e91d';
}
.icon-deployable-save:before {
    content: '\e91e';
}
.icon-deployable-search:before {
    content: '\e91f';
}
.icon-deployable-setting:before {
    content: '\e920';
}
.icon-deployable-settings-slider:before {
    content: '\e921';
}
.icon-deployable-share:before {
    content: '\e922';
}
.icon-deployable-social-fb:before {
    content: '\e923';
    color: #1877f2;
}
.icon-deployable-social-insta:before {
    content: '\e924';
}
.icon-deployable-social-linkedIn:before {
    content: '\e925';
    color: #0a66c2;
}
.icon-deployable-social-pinterest:before {
    content: '\e926';
    color: #cb1f24;
}
.icon-deployable-social-spotify:before {
    content: '\e927';
    color: #1ed760;
}
.icon-deployable-social-tiktok:before {
    content: '\e928';
    color: #ff004f;
}
.icon-deployable-social-tweet:before {
    content: '\e929';
    color: #1da1f2;
}
.icon-deployable-social-whatapp:before {
    content: '\e92a';
    color: #01e675;
}
.icon-deployable-social-yt:before {
    content: '\e92b';
    color: #ff0302;
}
.icon-deployable-sync:before {
    content: '\e92c';
}
.icon-deployable-text:before {
    content: '\e92d';
}
.icon-deployable-tick:before {
    content: '\e92e';
}
.icon-deployable-up:before {
    content: '\e92f';
    color: #6f767e;
}
.icon-deployable-upload:before {
    content: '\e930';
}
