@tailwind base;
@tailwind components;
@tailwind utilities;

/* purgecss start ignore */

/* Import Swiper styles */
@import 'swiper/css';
@import 'swiper/css/navigation';
@import 'swiper/css/pagination';
@import 'swiper/css/effect-fade';

@import 'react-toastify/dist/ReactToastify.css';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');
@font-face {
    font-family: 'Matter';
    src: local('Matter'), url(../../public/fonts/Matter-Regular.woff) format('woff');
    font-display: block;
}

@font-face {
    font-family: 'Matter';
    src: local('Matter'), url(../../public/fonts/Matter-SemiBold.woff) format('woff');
    font-weight: 500;
    font-display: block;
}

@font-face {
    font-family: 'Matter SQ';
    src: local('Matter SQ'), url(../../public/fonts/MatterSQ-Regular.woff) format('woff');
    font-display: block;
}

@font-face {
    font-family: 'Matter SQ';
    src: local('Matter SQ'), url(../../public/fonts/MatterSQ-SemiBold.woff) format('woff');
    font-weight: bold;
    font-display: block;
}

@keyframes links {
    from {
        opacity: 0;
        transform: translateX(-10px);
    }
    to {
        opacity: 1;
        transform: translateX(0px);
    }
}

.hover_widget_links {
    display: none;
    position: absolute;
    top: 0;
    animation: links 0.32s ease forwards;
    color: #fff;
    background-color: rgb(172, 172, 172);
    border-radius: 10px;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    border: 1px dashed #fff;
    z-index: 999;
    min-width: 100px;
}

::-webkit-scrollbar {
    background-color: darkgray;
    width: 8px;
}
 
::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.8);
}

em {
    font-style: italic;
}
strong {
    font-weight: bold;
}

html {
    line-height: 1 !important;
}

.header-text h1 {
    font-size: 2.8em;
}

.header-text h2 {
    font-size: 2.625em;
}

.header-text h3 {
    font-size: 1.875em;
}

.header-text h4 {
    font-size: 1.25em;
}

.header-text h5 {
    font-size: 0.85em;
}

.header-text h6 {
    font-size: 0.7em;
}

.header-text p {
    font-size: 1em;
}

.placeholder-dnd ~ div {
    transform: none !important;
}

.placeholder-dnd ~ [data-rbd-placeholder-context-id] {
    display: none !important;
}

.ghost-drag {
    margin: 0 auto;
    width: 30%;
}

#react-qrcode-logo {
    max-width: 200px;
    max-height: 200px;
}

.grecaptcha-badge {
    visibility: hidden;
}

.Toastify__toast--warning {
    background: #ffe8bc !important;
}

.Toastify__toast--error {
    background: #f5393d !important;
}

.Toastify__toast--success {
    background: #3ae29b !important;
}

.Toastify__toast > svg {
    color: white;
}

.Toastify__toast > button {
    color: white;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    margin: auto;
    margin-right: 5px;
    transform: scale(1.25);
}

.toastBody {
    font-family: 'Matter', sans-serif;
    color: white;
    font-size: 14px !important;
    border-radius: 9px !important;
    margin: 0 0 0 5px !important;
}

.width_80 {
    width: 80%;
}

.tox-toolbar__overflow > div:first-child {
    display: none;
}
